<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title" style="width: 100%; text-align: left">
                <span v-if="id">新增表单控件</span>
                <span v-if="!id">编辑表单控件</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <div class="formScroll">
                <a-row :gutter="24">
                    <a-col span="12">
                        <!--控件名称-->
                        <a-form-item :label="l('控件名称')">
                            <a-input :placeholder="l('控件名称')"
                                     v-decorator="[ 'Name',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 30, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                    <a-col span="12">
                        <!--显示文本-->
                        <a-form-item :label="l('显示文本')">
                            <a-input :placeholder="l('显示文本')"
                                     v-decorator="['Label',{
                                        rules: [
                                          { required: true, message: l('validation.required') },
                                          { max: 30, message: l('validation.maxlength') },
                                        ],
                                      },
                                    ]" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="12">
                        <a-form-item label="空白时文本">
                            <a-input :placeholder="l('空白时文本')"
                                     v-decorator="['Placeholder',{ rules: [] }]" />
                        </a-form-item>
                    </a-col>
                    <a-col span="12">
                        <a-form-item label="最大长度">
                            <a-input-number v-decorator="['MaxLength',{ rules: [] }]" placeholder="最大长度" style="width:363px;"></a-input-number>
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="12">
                        <!-- 是否必填 -->
                        <a-form-item :label="l('是否必填')">
                            <a-switch v-decorator="['DefaultImportant',{ rules: [] }]" checked-children="是" un-checked-children="否" />
                        </a-form-item>
                    </a-col>
                    <a-col span="12">
                        <!-- 是否可填写 -->
                        <a-form-item :label="l('是否可填写')">
                            <a-switch v-decorator="['IsClientReadonly',{ rules: [] }]" checked-children="是" un-checked-children="否" />
                        </a-form-item>
                    </a-col>
                </a-row>
                <a-row :gutter="24">
                    <a-col span="12">
                        <!--控件类型-->
                        <a-form-item :label="l('控件类型')">
                            <a-select :placeholder="l('控件类型')" @change="fieldTypeChange"
                                      v-decorator="['FieldType',{ rules: [ { required: true, message: l('validation.required') } ]}]">
                                <a-select-option v-for="item in ComponentList" :value="item.typeValue" :key="item.typeValue">{{item.typeName}}</a-select-option>
                            </a-select>
                        </a-form-item>
                    </a-col>
                    <a-col span="12" v-if="ShowOptions">
                        <!--选项值-->
                        <a-form-item :label="l('选项值')">
                            <a-input placeholder="选项值" style="width:100px;" v-model="firstOptionValue"></a-input>&nbsp;&nbsp;
                            <a-button type="primary" shape="circle" icon="plus" @click="addOption()"></a-button>
                        </a-form-item>
                        <a-form-item v-for="item in FieldOptions" :key="item.id">
                            <a-input placeholder="选项值" style="width:100px;" v-model="item.OptValue"></a-input>&nbsp;&nbsp;
                            <a-button type="primary" shape="circle" icon="minus" @click="removeOption(item.id)"></a-button>
                        </a-form-item>
                    </a-col>
                </a-row>
            </div>
            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button :loading="loading" type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import {message} from "ant-design-vue";
    import {ModalHelper} from "@/shared/helpers";   
    import {AppConsts} from "@/abpPro/AppConsts";
    import {fileDownloadService} from "@/shared/utils";

    export default {
        mixins: [ModalComponentBase],
        name: 'fieldResourceEdit',
        data() {
            return {
                ComponentList: [],
                ShowOptions: false,
                firstOptionValue: '',
                FieldOptions: [],
                currentOptionId: 0,
                spinning: false
            };
        },
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "register" });
        },
        created() {
            this.initFieldTypeData();
            this.fullData(); // 模态框必须,填充数据到data字段
            if(this.id) {
                this.getData();
            }
        },
        methods: {
            initFieldTypeData() {
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/FieldLibs/GetAllFieldType',
                    method: 'GET',
                    success(res) {
                        res.forEach(item => {
                            _this.ComponentList.push(item);
                        });
                    }
                });
            },
            addOption() {
                this.currentOptionId = this.currentOptionId + 1;
                this.FieldOptions.push({ id: this.currentOptionId, OptValue: '' });
            },
            removeOption(id) {
                for (let i = 0; i < this.FieldOptions.length; i++) {
                    let item = this.FieldOptions[i];
                    if (item.id == id) {
                        this.FieldOptions.splice(i, 1);
                        return;
                    }
                }
            },
            fieldTypeChange(value, options) {
                switch (value) {
                    case "ddselectfield":
                    case "ddmultiselectfield":
                    case "radioselectfield":
                        this.ShowOptions = true;
                        this.FieldOptions.splice(0, this.FieldOptions.length);
                        break;
                    default:
                        this.ShowOptions = false;
                        break;
                }
            },
            /**
             * 提交表单
             */
            async handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll(async (err, values) => {
                    if (!err) {
                        this.loading = true
                        let data = {
                            Name: values.Name,
                            Label: values.Label,
                            Placeholder: values.Placeholder,
                            MaxLength: values.MaxLength,
                            DefaultImportant: values.DefaultImportant,
                            IsClientReadonly: values.IsClientReadonly,
                            FieldType: values.FieldType,
                            SupportSetting: this.getFieldSupportSettings(values.FieldType),
                            DefaultOptions: null,
                            id: this.id ? this.id : 0
                        };

                        if (data.FieldType == 'radioselectfield' || data.FieldType == 'ddselectfield' || data.FieldType == 'ddmultiselectfield') {
                            if (this.firstOptionValue == '') {
                                abp.message.error(this.l("请至少填写一个选项值"));
                                return false;
                            }

                            var array = [{ idx: this.firstOptionValue, text: this.firstOptionValue}];
                            this.FieldOptions.forEach(item => {
                                if (item.OptValue != '') {
                                    array.push({
                                        idx: item.OptValue,
                                        text: item.OptValue
                                    });
                                }
                            });
                            data.DefaultOptions = JSON.stringify(array);
                        }
                        
                        let _this = this;
                        this.SendToService({
                            url: '/api/services/app/FieldLibs/CreateOrUpdate',
                            method: 'POST',
                            data: data,
                            success: (res) => {
                                this.$message.success(`操作成功`);
                                this.success(true);
                            },
                            complete: (e) => {
                                _this.loading = false;
                            }
                        });
                    }
                });
            },
            getFieldSupportSettings(fileType) {
                var html = '[]'
                switch (fileType) {
                    case 'textfield':
                    case 'textareafield':
                        html = '["field","label","placeholder","required","important","print","canTheUserFillIn"]';
                        break;
                    case 'numberfield':
                        html = '["field","label","placeholder","required","important","print","uint","canTheUserFillIn"]';
                        break;
                    case 'ddselectfield':
                    case 'ddmultiselectfield':
                    case 'radioselectfield':
                        html = '["field","label","placeholder","options","sync","required","important","print","canTheUserFillIn"]';
                        break;
                    case 'ddattachment':
                        html = '["label", "required", "important", "print", "field"]';
                        break;
                    case 'dddatefield':
                        html = '["field","label","placeholder","dateformat","required","important","print","canTheUserFillIn"]';
                        break;
                    case 'dddaterangefield':
                        html = '["field","label","placeholder","required","important","canTheUserFillIn"]';
                        break;
                }
                return html;
            },
            SendToService(opts) {
                this.spinning = true;
                let options = {
                    url: this.$apiUrl + opts.url,
                    method: opts.method,
                    data: opts.data,
                    params: opts.params,
                    headers: { "Content-Type": "application/json-patch+json" },
                };
                this.$api.request(options).then((res) => {
                    this.spinning = false;
                    if(opts.complete && typeof opts.complete == 'function') {
                        opts.complete(res);
                    }
                    if (res.status == 200) {
                        if (opts.success && typeof opts.success == 'function') {
                            opts.success(res.data);
                        }
                    } else {
                        abp.message.error(res.statusText);
                    }
                }).catch(e => {
                    console.error(e);
                    this.spinning = false;
                    if(opts.complete && typeof opts.complete == 'function') {
                        opts.complete(e);
                    }
                });
            },
            getData() {
                let _this = this;
                let data = {
                    id: this.id
                };
                this.SendToService({
                    url: '/api/services/app/FieldLibs/GetById',
                    method: 'get',
                    params: data,
                    success: (res) => {
                        this.form.setFieldsValue({
                            Name: res.name,
                            Label: res.label,
                            Placeholder: res.placeholder,
                            MaxLength: res.maxLength ? res.maxLength : 0,
                            DefaultImportant: res.defaultImportant,
                            IsClientReadonly: res.isClientReadonly,
                            FieldType: res.fieldType
                        });
                        
                        let array = _this.getFieldOptions(res.defaultOptions, _this);
                        if(array.length > 0) {
                            _this.firstOptionValue = array[0].OptValue;
                            array.splice(0,1);
                            _this.ShowOptions = true;
                        }
                        _this.FieldOptions = array;
                    }
                })
            },
            getFieldOptions(options, that){
                var array = [];
                if(options && options != '') {
                    let json = JSON.parse(options);
                    let index = 0;
                    json.forEach(item => {
                        index ++;
                        array.push({
                            id: index,
                            OptValue: item.text
                        });
                    });
                    that.currentOptionId = index;
                }

                return array;
            }
        }
    }
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    .ant-cascader-picker {
        width: 85% !important;
    }

    .ant-btn-primary {
        margin-left: 10px;
    }

    .formScroll {
        max-height: 600px;
        overflow-y: scroll;
        -ms-overflow-style: none; /* IE 10+ */
        scrollbar-width: none; /* Firefox */
    }

    .dianmi-select {
        width: 40%;
        margin-left: 5px;
    }

    ::-webkit-scrollbar {
        display: none; /* Chrome Safari */
    }
</style>