import { render, staticRenderFns } from "./addoredit.vue?vue&type=template&id=8d78e1ec&scoped=true&"
import script from "./addoredit.vue?vue&type=script&lang=js&"
export * from "./addoredit.vue?vue&type=script&lang=js&"
import style0 from "./addoredit.vue?vue&type=style&index=0&id=8d78e1ec&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8d78e1ec",
  null
  
)

export default component.exports