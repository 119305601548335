<template>
    <a-spin :spinning="spinning">
        <!-- <react-table :table-data="data"
          :total-items="totalItems"
          :showTotal="showTotalFun"
          :actions-type="actionsType"
          :scroll="{ x: 1000 }"
          :columns="columns"
          :current-page="pageNumber"
          :is-full="true"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSortData="updateSortData"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
                     > -->

        <ele-table
            ref="rt"
            :columns="columns"
            :table-data="data"
            :total-items="totalItems"
            :actions-type="actionsType"
            :is-full="true"
            :current-page="pageNumber"
            :hide-row-selection="true"
            @emitRefreshData="clearFilterAndRefresh"
            @emitSortData="updateSortData"
            @emitSelectChange="updateSelectChange"
            @emitOnPageChange="updatePageChange"
            @emitShowSizeChange="updateShowSizeChange"
        >
            <!-- 查询 -->
            <a-row :gutter="8">
                <a-col class="gutter-row" :span="4">
                    <a-input v-model.trim="SearchDto.Name"
                             :placeholder="l('请输入控件名称')"
                             allow-clear />
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-select v-model="SearchDto.FieldType"
                              :placeholder="l('请选择控件类型')"
                              style="width: 100%"
                              allow-clear>
                        <a-select-option v-for="item in ComponentList" :value="item.typeValue" :key="item.typeValue">{{item.typeName}}</a-select-option>
                    </a-select>
                </a-col>
                <a-col class="gutter-row" :span="4">
                    <a-button type="primary"
                              @click="getData()"
                              v-if="isGranted('Pages.LGModul.FieldResource.Query')"> 
                              <!-- <a-icon type="search"></a-icon> -->
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>
                <a-col class="gutter-row" :span="12" align="right">
                    <a-button v-if="isGranted('Pages.LGModul.FieldResource.Create')" type="primary"
                              @click="addorEdit()">
                        <a-icon type="plus" />
                        <span>{{ l("Create") }}</span>
                    </a-button>
                </a-col>
            </a-row>
        </ele-table>
    </a-spin>
</template>
<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import { CommonServiceProxy, initFieldLibOptions } from "@/shared/common-service";
    import { fileDownloadService } from "@/shared/utils";
    import moment from "moment";
    import EleTable from "@/components/ele-table";
    import ReactModify from "@/components/react-modify";
    import { AppConsts } from "@/abpPro/AppConsts";
    import { environment } from "@/environments/environment";
    import AddOrEditModal from './addoredit.vue';

    export default {
        mixins: [AppComponentBase],
        name: "fieldResource-index",
        components: { EleTable },
        data() {
            return {
                spinning: false,
                selectedRowKeys: [],
                SearchDto: {
                    Name: '',
                    FieldType: undefined
                },
                // 总记录数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 总页数
                totalPages: 1,
                // 
                pagerange: [1, 1],
                // 每页显示数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: { sorting: "", maxResultCount: 50, skipCount: 0 },
                // 表头
                columns: [],
                actionsType: null,
                // 数据
                data: [],
                           ComponentList: []
                // ComponentList: [{ typeName: '请选择', typeValue: ''}]
            };
        },
        created() {
            this.initFieldType();
            this.getData();
            this.initActionsType();
            this.initColums();
        },
        methods: {
            initColums() {
                let _this = this;
                this.columns = [
                    {
                        title: "控件名称",
                        dataIndex: "name",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "name" },
                    },
                    {
                        title: "显示文本ֵ",
                        dataIndex: "label",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "label" },
                    },
                    {
                        title: "空白显示文本",
                        dataIndex: "placeholder",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "placeholder" }
                    },
                    {
                        title: "最大长度",
                        dataIndex: "maxLength",
                        sorter: false,
                        align: "center",
                        // width: 100,
                        scopedSlots: { customRender: "maxLength" }
                    },
                    {
                        title: "是否必填",
                        dataIndex: "defaultImportant",
                        sorter: false,
                        align: "center",
                        // width: 80,
                        scopedSlots: { customRender: "defaultImportant" },
                        customRender: (text) => {
                            return text ? '是' : '否';
                        }
                    },
                    {
                        title: "是否可填写",
                        dataIndex: "isClientReadonly",
                        sorter: false,
                        // width: 80,
                        align: "center",
                        scopedSlots: { customRender: "isClientReadonly" },
                        customRender: (text) => {
                            return text ? '是' : '否';
                        }
                    },
                    {
                        title: "控件类型",
                        dataIndex: "fieldType",
                        sorter: false,
                        align: "center",
                        // width: 120,
                        scopedSlots: { customRender: "fieldType" },
                        customRender: (text, record, index) => {
                            var rows = this.ComponentList.filter(item => item.typeValue == text);
                            return rows && rows.length > 0 ? rows[0].typeName : '';
                        }
                    },
                    {
                        title: "选项值",
                        dataIndex: "defaultOptions",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "defaultOptions" },
                        customRender: (text,row,index) => {
                            switch (row.fieldType) {
                                case 'ddselectfield':
                                    if (text) {
                                        var html = text.trim().replace('[', '').replace(']', '');
                                        html = html.replace('","', ',');
                                        html = html.replace('"', '');
                                        return html;
                                    }
                                    break;
                                case 'ddmultiselectfield':
                                case 'radioselectfield':
                                    if (text) {
                                        var array = JSON.parse(text);
                                        var val = '';
                                        if (array) {
                                            array.forEach(item => {
                                                val += item.text + ',';
                                            });
                                            return val.substring(0, val.length - 1);
                                        }
                                    }
                                    break;
                            }
                            return '';
                        }
                    }
                ];
            },

            // 初始化列表action
            initActionsType() {
                let _this = this;
                let obj = {
                    type: "fieldResource",
                    isShow: true,
                    fns: {
                        detailList: [
                            {
                                granted: this.isGranted("Pages.LGModul.FieldResource.Edit"),
                                name: this.l("Edit"),
                                icon: "profile",
                                width: "30%",
                                fn: (data) => {
                                _this.addorEdit(data);
                                },
                            },
                            {
                                granted: this.isGranted("Pages.LGModul.FieldResource.Delete"),
                                width: "30%",
                                name: this.l("删除"),
                                icon: "close",
                                tipsDesc: "是否删除此数据?",
                                fn: (data) => {
                                    _this.delFieldResource(data.id);
                                },
                            }
                        ],
                        dropList: []
                    },
                };
                this.actionsType = obj;
            },
            getData () {
                let data = {
                    name: this.SearchDto.Name,
                    fieldType: !this.SearchDto.FieldType ? '' : this.SearchDto.FieldType,
                    sorting: this.request.sorting,
                    skipCount: this.request.skipCount,
                    maxResultCount: this.request.maxResultCount
                };
                this.$nextTick(() => {
                    this.$refs.rt.clearData();
                })
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/FieldLibs/GetList',
                    method: 'POST',
                    data: data,
                    success(res) {
                        _this.data.splice(0, _this.data.length);
                        _this.totalItems = res.totalCount;
                        res.items.forEach(item => _this.data.push(item));
                        _this.data = res.items.map(item => {
                            const _fieldType = _this.ComponentList.filter(v => v.typeValue == 'text');
                            const fieldType = _fieldType && _fieldType.length > 0 ? _fieldType[0].typeName : '';
                            return{
                                ...item,
                                defaultImportant: item.defaultImportant ? '是' : '否',
                                isClientReadonly: item.isClientReadonly ? '是' : '否',
                                fieldType: fieldType,
                                defaultImportant: item.defaultImportant ? '是' : '否',
                                defaultOptions: initFieldLibOptions(item, item.defaultOptions),
                            }
                        })
                    }
                });
            },
            initFieldType() {
                let _this = this;
                this.SendToService({
                    url: '/api/services/app/FieldLibs/GetAllFieldType',
                    method: 'GET',
                    success(res) {
                        res.forEach(item => {
                            _this.ComponentList.push(item);
                        });
                    }
                });
            },
            showTotalFun() {
                return this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
            },
            refreshGoFirstPage() {
                this.SearchDto.Name = '';
                this.SearchDto.FieldType = undefined;
                this.pageNumber = 1;
      this.request.SkipCount = 0;
                this.getData();
            },
            delFieldResource(id) {
                let _this = this;
                abp.message.confirm('是否删除此记录', (fn) => {
                    if(fn) {
                        var array = [];
                        array.push(id);
                        _this.SendToService({
                            url: '/api/services/app/FieldLibs/GetFieldDels',
                            method: 'POST',
                            data: array,
                            success(res) {
                                abp.message.success('删除成功');
                                _this.refreshGoFirstPage();
                            }
                        });
                    }
                });
            },
            addorEdit(data) {
                ModalHelper.create(AddOrEditModal, {
                    id: data ? data.id : undefined
                }, {
                    width: "800px",
                }).subscribe((res) => {
                    this.refreshGoFirstPage();
                });
            },
            SendToService(opts) {
                this.spinning = true;
                let options = {
                    url: this.$apiUrl + opts.url,
                    method: opts.method,
                    data: opts.data,
                    params: opts.params,
                    headers: { "Content-Type": "application/json-patch+json" },
                };
                this.$api.request(options).then((res) => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(res);
                    }
                    if (res.status == 200) {
                        if (opts.success && typeof opts.success == 'function') {
                            opts.success(res.data);
                        }
                    } else {
                        abp.message.error(res.statusText);
                    }
                }).catch(e => {
                    this.spinning = false;
                    if (opts.complete && typeof opts.complete == 'function') {
                        opts.complete(e);
                    }
                    console.error(e);
                });
            },
            /*
            分页相关
            */
            // 情况查询条件并重新查询
            clearFilterAndRefresh() {
                this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
                this.SearchDto.Name = "";
                this.SearchDto.FieldType = undefined;
                this.getData();
            },
            // 排序更新data
            updateSortData(newV) {
                let { columnKey, order } = newV;
                this.request.sorting = order ? `${columnKey} ${order}` : "";
                this.getData();
            },
            // table选择事件
            updateSelectChange(newV) {
                let { selectedRowKeys, selectedRows } = newV;
                this.onSelectChange(selectedRowKeys, selectedRows);
            },
            onSelectChange(keys, rows) {
                if(!!this.selectedRows){
                    this.selectedRows.splice(0, this.selectedRows.length);
                    for (let i = 0; i < rows.length; i++) {
                        this.selectedRows.push(rows[i]);
                    }
                }
            },
            // 分页
            updatePageChange(newV) {
                let { page, pageSize } = newV;
                this.onChange(page, pageSize);
            },
            onChange(page, pageSize) {
                this.pageNumber = page;
                this.request.SkipCount =
                    (page - 1) * this.request.maxResultCount;
                this.getData();
            },
            updateShowSizeChange(newV) {
                debugger;
                let { current, size } = newV;
                this.showSizeChange(current, size);
            },
            showSizeChange(current, size) {
                this.pageNumber = 1;
                this.request.maxResultCount = size;
                this.getData();
            }
        }
    };
</script>

<style lang="less" scoped>
    .btn--container {
        margin-bottom: 20px;
    }

    .btn--footer {
        // border: 1px solid #e8e8e8;
        // margin: 20px;
        // padding: 20px;
        .table-delete

    {
        margin-left: 10px;
        margin-right: 10px;
    }

    }

    .btn--footer {
        margin-bottom: 20px;
    }
</style>
